import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Alert,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Tooltip,
  MenuItem,
  OutlinedInput,
  Select,
  Chip,
  Box,
  Stack,
} from '@mui/material';

import {
  Info as InfoIcon,
  Delete as DeleteIcon,
  MailOutline as MailOutlineIcon,
} from '@mui/icons-material';

import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';

import useRoles from 'hooks/useRoles';
import useClients from 'hooks/useClients';
import useConnections from 'hooks/useConnections';
import useInvitationsPost from 'hooks/useInvitationsPost';
import IRole from 'interfaces/Role';
import IConnection from 'interfaces/Connection';
import { invitationRequestSchema, InvitationRequestSchemaType } from 'interfaces/InvitationRequest';

{
  /* 
The invitation form is quite complicated as it combines a) MaterialUI form elements, b)validation with zod and c) react-hook-form
There is also a part of the form which is dynamic, meaning that user can add and remove invitees.  
Sources: 
- https://react-hook-form.com/get-started#IntegratingwithUIlibraries
- https://react-hook-form.com/docs/usefieldarray
- https://www.youtube.com/watch?v=tWjAnHWP92E
*/
}

interface Props {
  org_id: string;
  mutate_invitations: () => void | Promise<void>;
}

const InviteMembersButton = ({ org_id, mutate_invitations }: Props) => {
  const { user } = useAuth0();
  const [open, setOpen] = useState(false);
  const { roles } = useRoles();
  const { clients } = useClients();
  const { connections } = useConnections(org_id);
  const { trigger, inProgress } = useInvitationsPost(org_id);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: {
      client_id: '',
      invitees: [{ email: '', roles: [], connection_id: '' }],
    },
    resolver: zodResolver(invitationRequestSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitees',
  });

  const onSubmit = useCallback((values: InvitationRequestSchemaType) => {
    trigger({ ...values, inviter: user?.name })
      .then(() => {
        mutate_invitations();
        closeDialog();
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (clients?.length === 1) {
      setValue('client_id', clients[0].client_id);
    }
  }, [clients, setValue]);

  return (
    <>
      <Button
        sx={{ marginBottom: '15px' }}
        variant="outlined"
        title="Invite new members to organization"
        onClick={openDialog}
        endIcon={<MailOutlineIcon />}>
        Invite
      </Button>
      <Dialog open={open} onClose={closeDialog} fullWidth={true} maxWidth="lg">
        <DialogTitle>Invite users to organization</DialogTitle>
        <DialogContent>
          <form id="invitation-form" onSubmit={handleSubmit(onSubmit)}>
            <Alert variant="outlined" severity="warning" sx={{ marginBottom: '15px' }}>
              This form is mainly for inviting (email,connection) combinations that are not already
              in Auth0.
              <p />
              If you are adding an existing (email,connection) combination to this organization,
              using this form will add them to the org and also send them an invitation email (the
              user is added to the organization even if they do not accept the invitation, so this
              can be confusing).
              <p />
              If you do not wish an existing user to receive an email when you add them to this
              organization, go to the Users page, locate the user and use the "Add to organization"
              button.
            </Alert>
            <DialogContentText sx={{ marginBottom: '15px' }}>
              Select the organization-enabled application that you want to invite the user for.
              <Tooltip title="For example, if the dropdown has applications Tywin and Collaboration, inviting an (email, connection) combination for application Tywin and organization X would give the user access to application Tywin for org X - but would not give the user access to application Collaboration for org X.">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DialogContentText>
            {clients !== undefined && (
              <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <Controller
                  name="client_id"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl sx={{ m: 1, minWidth: 250 }}>
                        <InputLabel id="application-label">Application</InputLabel>
                        <Select
                          labelId="application-label"
                          required
                          id="application-select"
                          label="Application"
                          error={!!errors.client_id}
                          value={field.value || ''}
                          onChange={field.onChange}>
                          {clients.map((client: { name: string; client_id: string }) => (
                            <MenuItem key={client.client_id} value={client.client_id}>
                              {client.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.client_id && (
                          <FormHelperText>{errors.client_id?.message}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Box>
            )}

            <DialogContentText sx={{ marginBottom: '15px' }}>
              You must specify an email address to send invitations. You should also specify one or
              more roles and a connection type. Make sure you have read the Help page if you are
              unsure about these values.
            </DialogContentText>

            {/* Invitee row ------------------------------ */}
            {fields.map((invitee, index) => (
              <Stack direction="row" spacing={1} key={invitee.id} sx={{ marginBottom: '15px' }}>
                {/* Invitee email ------------------------------ */}
                <Controller
                  name={`invitees.${index}.email`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      error={!!errors.invitees?.[index]?.email}
                      helperText={errors.invitees?.[index]?.email?.message}
                      id={`email-${index}`}
                      label="Email"
                      fullWidth
                      {...field}
                    />
                  )}
                />

                {/* Invitee roles ------------------------------ */}
                {roles !== undefined && (
                  <Controller
                    name={`invitees.${index}.roles`}
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ m: 1, width: '100%' }}>
                        <InputLabel id="role-label">Roles</InputLabel>
                        <Select
                          labelId="role-label"
                          id={`role-select-${index}`}
                          multiple
                          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                          renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map(value => {
                                const role = roles.find((r: IRole) => r.id === value);
                                return <Chip key={value} label={role ? role.name : value} />;
                              })}
                            </Box>
                          )}
                          label="Roles"
                          error={!!errors.invitees?.[index]?.roles}
                          {...field}>
                          {roles.map((role: IRole) => (
                            <MenuItem key={role.id} value={role.id}>
                              {role.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.invitees?.[index]?.roles && (
                          <FormHelperText>
                            {errors.invitees?.[index]?.roles?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}

                {/* Invitee connection ------------------------------ */}
                {connections !== undefined && (
                  <Controller
                    name={`invitees.${index}.connection_id`}
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ m: 1, width: '100%' }}>
                        <InputLabel id={`connection-label-${index}`}>Connections</InputLabel>
                        <Select
                          labelId={`connection-label-${index}`}
                          id={`connection-select-${index}`}
                          error={!!errors.invitees?.[index]?.connection_id}
                          label="Connection"
                          {...field}>
                          {connections.map((connection: IConnection) => (
                            <MenuItem
                              key={connection.connection_id}
                              value={connection.connection_id}>
                              {connection.connection.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.invitees?.[index]?.connection_id && (
                          <FormHelperText>
                            {errors.invitees?.[index]?.connection_id?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}

                <IconButton
                  title="Remove Invitee"
                  aria-label="delete"
                  onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}

            <Button onClick={() => append({ email: '', roles: [], connection_id: '' })}>
              Add more
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            form="invitation-form"
            type="submit"
            loading={inProgress}
            disabled={!isValid}>
            Invite
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteMembersButton;
