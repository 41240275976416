import { useState } from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ConfirmationDialog from 'components/ConfirmationDialog';
import useUserDelete from 'hooks/useUserDelete';
import ErrorDialog from './ErrorDialog';
import { FetcherError } from '../utils/fetcher';

import { MutatorCallback } from 'swr';

interface Props {
  user_id: string;
  user_email: string;
  mutateData: MutatorCallback;
}

const DeleteUserButton = ({ user_id, user_email, mutateData }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger: userDelete } = useUserDelete(user_id);
  const [failed, setFailed] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleDialogOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const deleteUser = async () => {
    try {
      await userDelete(
        { id: user_id },
        {
          onSuccess: () => {
            mutateData();
            setDialogOpen(false);
          },
          onError: (error: unknown) => {
            setFailed(true);
            setDialogOpen(false);
            if (error instanceof FetcherError) {
              // error.message is the same for all errors, see fetcher.tsx
              setErrorMessage(
                error.bodyText + ' (HTTP ' + error.status + ': ' + error.statusText + ')',
              );
              setOpenErrorDialog(true);
            } else {
              setErrorMessage('Unexpected error!');
              setOpenErrorDialog(true);
            }
          },
        },
      );
    } catch (err) {
      console.error('Failed', err);
    }
  };

  return (
    <>
      <IconButton
        color="secondary"
        aria-label="Delete"
        title="Delete user (even if they belong to an organization)"
        size="small"
        onClick={handleDialogOpen}>
        <PersonRemoveIcon fontSize="small" />
      </IconButton>
      <ConfirmationDialog
        title="Delete user from Auth0 (and remove from all organizations)"
        description={
          <>
            <Alert variant="outlined" severity="info" sx={{ marginBottom: '15px' }}>
              If you want to just remove this user from an organization, go to the organization's
              page and remove the user from the organization from that page.
            </Alert>
            Are you sure you want to delete <strong>{user_email}</strong> with ID{' '}
            <strong>{user_id}</strong> from Auth0?
          </>
        }
        confirmationText="Delete"
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        confirmAction={deleteUser}
      />
      <ErrorDialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
      <Snackbar open={failed} autoHideDuration={5000}>
        <Alert severity="error">Whoops! Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default DeleteUserButton;
