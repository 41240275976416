import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface BulkPostResponse {
  completedOrgIds: string[];
  failedOrgIds: string[];
}

const useOrgMembersBulkPost = (orgIds: string[], roleID: string, users: string[]) => {
  const [inProgress, setInProgress] = useState(false);
  const [completedOrgIds, setCompletedOrgIds] = useState<string[]>([]);
  const [failedOrgIds, setFailedOrgIds] = useState<string[]>([]);
  const [currentOrgIndex, setCurrentOrgIndex] = useState<number | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const postMembers = async (orgId: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/organizations/${orgId}/members`;
    const token = await getAccessTokenSilently();

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ roleID, users }),
    });

    if (!response.ok) {
      throw new Error(`Failed to add members`);
    }
  };

  const trigger = useCallback(async (): Promise<BulkPostResponse> => {
    if (inProgress || orgIds.length === 0) {
      return { completedOrgIds: [], failedOrgIds: [] };
    }

    setInProgress(true);
    setCompletedOrgIds([]);
    setFailedOrgIds([]);
    setCurrentOrgIndex(0);

    for (let i = 0; i < orgIds.length; i++) {
      setCurrentOrgIndex(i);
      try {
        await postMembers(orgIds[i]);
        setCompletedOrgIds(prev => [...prev, orgIds[i]]);
      } catch (error) {
        console.error(`Error adding members to org with ID ${orgIds[i]}`, error);
        setFailedOrgIds(prev => [...prev, orgIds[i]]);
      }
    }

    setCurrentOrgIndex(null);
    setInProgress(false);

    return {
      completedOrgIds: orgIds.filter(id => !failedOrgIds.includes(id)),
      failedOrgIds: [...failedOrgIds],
    };
  }, [orgIds, roleID, users, inProgress]);

  return {
    trigger,
    inProgress,
    completedOrgIds,
    failedOrgIds,
    currentOrgId: currentOrgIndex !== null ? orgIds[currentOrgIndex] : null,
  };
};

export default useOrgMembersBulkPost;
