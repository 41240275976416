import useMutate from '../useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useRepoPost = () => {
  const url = `${REACT_APP_API_BASE_URL}/github/repos`;

  const { trigger, inProgress } = useMutate(url, 'POST');

  return { trigger, inProgress };
};

export default useRepoPost;
