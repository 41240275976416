import { useState } from 'react';
import {
  Alert,
  CircularProgress,
  Chip,
  List,
  ListItem,
  ListSubheader,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { HourglassEmpty as HourglassEmptyIcon, Cancel as CancelIcon } from '@mui/icons-material';

import ConfirmationDialog from 'components/ConfirmationDialog';
import useRoleDelete from 'hooks/useRoleDelete';
import useRolePermissions from 'hooks/useRolePermissions';
import IPermission from 'interfaces/Permission';
import { groupBy, GroupedItems } from 'utils/groupBy';

interface Props {
  role_id: string;
  role_name: string;
  user_id: string;
  user_email: string;
  org_id: string;
  mutateRoles: () => void | Promise<void>;
}

const RoleChip = ({ role_id, role_name, user_id, user_email, org_id, mutateRoles }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger, inProgress } = useRoleDelete(org_id, user_id, role_id);
  const [failed, setFailed] = useState(false);
  const {
    permissions,
    isLoading: permissionsLoading,
    error: permissionsError,
  } = useRolePermissions(role_id);

  const handleDelete = () => {
    trigger({})
      .then(() => {
        mutateRoles();
        setDialogOpen(false);
      })
      .catch(e => {
        setFailed(true);
        console.error(e);
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const permissionsTooltip = () => {
    if (permissionsLoading) return <CircularProgress />;
    if (permissionsError) return 'Failed to fetch permissions';

    if (permissions) {
      const groupedPermissions: GroupedItems<IPermission> = groupBy(
        permissions,
        'resource_server_name',
      );
      return (
        <>
          {Object.keys(groupedPermissions).map(server_name => (
            <List
              key={`server-${server_name}`}
              dense
              subheader={
                <ListSubheader
                  disableGutters
                  sx={{
                    backgroundColor: 'unset',
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'bold',
                    lineHeight: '24px',
                  }}>
                  {server_name}
                </ListSubheader>
              }>
              {groupedPermissions[server_name].map(permission => (
                <ListItem key={`permission-${server_name}-${permission.permission_name}`}>
                  {permission.permission_name}
                </ListItem>
              ))}
            </List>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <Tooltip
        title={permissionsTooltip()}
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -7],
                },
              },
            ],
          },
        }}>
        <Chip
          key={role_id}
          label={role_name}
          variant="outlined"
          onDelete={handleDialogOpen}
          deleteIcon={inProgress ? <HourglassEmptyIcon /> : <CancelIcon />}
        />
      </Tooltip>
      <ConfirmationDialog
        title="Remove role"
        description={
          <>
            Are you sure you want to remove role <strong>{role_name}</strong> from user{' '}
            <strong>{user_email}</strong> with ID <strong>{user_id}</strong>?
          </>
        }
        confirmationText="Remove"
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        confirmAction={handleDelete}
      />
      <Snackbar open={failed} autoHideDuration={5000}>
        <Alert severity="error">Whoops! Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default RoleChip;
