import React, { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Toolbar,
} from '@mui/material';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import useOrgMembersBulkPost from 'hooks/useOrgMembersBulkPost';
import useOrgs from 'hooks/useOrgs';
import useRoles from 'hooks/useRoles';

import IOrganization from 'interfaces/Organization';
import IRole from 'interfaces/Role';

interface Props {
  users: GridRowSelectionModel;
  mutation?: () => void | Promise<void>;
}

const AddUsersToOrgButton = ({ users, mutation }: Props) => {
  const [open, setOpen] = useState(false);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [role, setRole] = useState('');
  const [failed, setFailed] = useState(false);
  const [operationStatus, setOperationStatus] = useState<'completed' | 'failed' | null>(null);

  const { orgs } = useOrgs();
  const { roles } = useRoles();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const dismissAlert = () => {
    setFailed(false);
  };

  const updateOrganizations = (event: SelectChangeEvent<typeof organizations>) => {
    const value = event.target.value;
    setOrganizations(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const updateRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  const {
    trigger: addUsersToOrgs,
    inProgress,
    completedOrgIds,
    failedOrgIds,
    currentOrgId,
  } = useOrgMembersBulkPost(organizations, role, users as string[]);

  const submitRequest = async () => {
    setOperationStatus(null);
    try {
      await addUsersToOrgs();
      setOperationStatus('completed');
      if (typeof mutation !== 'undefined') {
        mutation();
      }
      closeDialog();
    } catch (e) {
      console.error(e);
      setOperationStatus('failed');
    }
  };

  return (
    <>
      <Toolbar disableGutters>
        <Button
          variant="outlined"
          title="Add users to organization"
          startIcon={<GroupAddIcon />}
          disabled={users.length === 0}
          onClick={openDialog}>
          Add to Organization
        </Button>
      </Toolbar>
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogTitle>Add users to organizations</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select organizations and a role to add the following users:
          </DialogContentText>
          <ul>
            {users.map(user => (
              <li key={user}>{user}</li>
            ))}
          </ul>
          <Stack direction="row" spacing={2}>
            {orgs !== undefined && (
              <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel id="organization-label">Organizations</InputLabel>
                <Select
                  labelId="organization-label"
                  id="organization-select"
                  multiple
                  input={<OutlinedInput id="select-multiple-chip" label="Organizations" />}
                  value={organizations}
                  label="Organizations"
                  onChange={updateOrganizations}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => {
                        const org = orgs.find((o: IOrganization) => o.id === value);
                        return <Chip key={value} label={org ? org.display_name : value} />;
                      })}
                    </Box>
                  )}>
                  {orgs.map((org: IOrganization) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {roles !== undefined && (
              <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role-select"
                  value={role}
                  label="Role"
                  onChange={updateRole}>
                  {roles.map((role: IRole) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        {inProgress && (
          <>
            <LinearProgress
              variant="determinate"
              value={
                (completedOrgIds.length / (completedOrgIds.length + failedOrgIds.length)) * 100
              }
              sx={{ my: 2 }}
            />
            <DialogContentText variant="caption">
              Currently adding users to: {currentOrgId}
            </DialogContentText>
          </>
        )}
        <DialogActions>
          <Button onClick={closeDialog} disabled={inProgress}>
            Cancel
          </Button>
          <Button onClick={submitRequest} color="primary" disabled={inProgress}>
            {inProgress ? 'In Progress...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={operationStatus === 'completed'}
        autoHideDuration={5000}
        onClose={() => setOperationStatus(null)}>
        <Alert severity="success">
          Successfully added users to {completedOrgIds.length} organizations.
          {failedOrgIds.length > 0 &&
            ` Failed to add users to ${failedOrgIds.length} organizations.`}
        </Alert>
      </Snackbar>
      <Snackbar open={failed} autoHideDuration={5000} onClose={dismissAlert}>
        <Alert severity="error">Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default AddUsersToOrgButton;
