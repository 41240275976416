import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Group, Help, Work, Shield, GitHub } from '@mui/icons-material';

interface navItem {
  index: number;
  icon: JSX.Element;
  label: string;
  route?: string;
  external?: boolean;
  url?: string;
}

const auth0Items: navItem[] = [
  {
    index: 0,
    icon: <Group />,
    label: 'Users',
    route: 'users',
  },
  {
    index: 1,
    icon: <Work />,
    label: 'Organizations',
    route: 'organizations',
  },
  {
    index: 2,
    icon: <Shield />,
    label: 'Roles',
    route: 'roles',
  },
];

const githubItems: navItem[] = [
  {
    index: 3,
    icon: <GitHub />,
    label: 'Create repository',
    route: 'create-github-repo',
  },
];

const helpItems: navItem[] = [
  {
    index: 4,
    icon: <Help />,
    label: 'Confluence',
    external: true,
    url: 'https://bare-square.atlassian.net/wiki/x/BoBnGQ',
  },
];

const NavbarList = () => {
  const navigate = useNavigate();

  const handleClick = (item: navItem) => {
    if (item.external && item.url) {
      window.open(item.url, '_blank');
    } else if (item.route) {
      navigate(item.route);
    } else console.error('Selected option has no route and is not an external link');
  };

  const renderMenuItems = (items: navItem[]) =>
    items.map(item => (
      <ListItem key={item.index} onClick={() => handleClick(item)} disablePadding>
        <ListItemButton>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      </ListItem>
    ));

  return (
    <>
      <List subheader={<ListSubheader>Auth0</ListSubheader>}>{renderMenuItems(auth0Items)}</List>
      <List subheader={<ListSubheader>GitHub</ListSubheader>}>{renderMenuItems(githubItems)}</List>
      <List subheader={<ListSubheader>Help</ListSubheader>}>{renderMenuItems(helpItems)}</List>
    </>
  );
};

export default NavbarList;
