import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useRepoPost from 'hooks/github/useRepoPost';
import SuccessDialog from '../../components/SuccessDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { FetcherError } from '../../utils/fetcher';

const GitHubRepoPage = () => {
  const [creatorUsername, setCreatorUsername] = useState('');
  const [repoName, setRepoName] = useState('');
  const [repoDescription, setRepoDescription] = useState('');
  const [templateRepo, setTemplateRepo] = useState('');
  const { trigger, inProgress } = useRepoPost();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async () => {
    try {
      await trigger(
        { creatorUsername, repoName, repoDescription, templateRepo },
        {
          onSuccess: () => {
            setSuccessMessage(`Repo ${repoName} created successfully!`);
            setOpenSuccessDialog(true);
            setRepoName('');
            setRepoDescription('');
            setTemplateRepo('');
          },
          onError: (error: unknown) => {
            if (error instanceof FetcherError) {
              setErrorMessage(
                error.bodyText + ' (HTTP ' + error.status + ': ' + error.statusText + ')',
              );
            } else {
              setErrorMessage('Unexpected error!');
            }
            console.error('Error creating repo:', errorMessage);
            setOpenErrorDialog(true);
          },
        },
      );
    } catch (error: unknown) {
      console.error('Failed', error);
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    setSuccessMessage('');
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage('');
  };

  return (
    <Card sx={{ maxWidth: '50%', margin: 'auto' }}>
      <CardHeader title="Create GitHub Repository" />
      <Divider />
      <CardContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          <ul>
            <li>
              You <b>must</b> have role User Management <b>GitHub</b> Admin to create a repository.
            </li>
            <li>The supplied GitHub username will become an admin to the new repo.</li>
            <li>Prefer dashes instead of underscores for repository names.</li>
          </ul>
        </Typography>

        <TextField
          fullWidth
          label="GitHub username (not email)"
          value={creatorUsername}
          onChange={e => setCreatorUsername(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Repository Name"
          value={repoName}
          onChange={e => setRepoName(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Repository Description"
          value={repoDescription}
          onChange={e => setRepoDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="template-repo-label">Template Repository</InputLabel>
          <Select
            labelId="template-repo-label"
            value={templateRepo}
            onChange={e => setTemplateRepo(e.target.value)}
            label="Template Repository">
            <MenuItem value="">None</MenuItem>
            <MenuItem value="template-python">template-python</MenuItem>
            <MenuItem value="template-typescript">template-typescript</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          title="Create GitHub repository"
          color="primary"
          onClick={handleSubmit}
          sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          disabled={inProgress || !creatorUsername.trim() || !repoName.trim()}>
          Create Repository
        </Button>

        <SuccessDialog
          open={openSuccessDialog}
          onClose={handleCloseSuccessDialog}
          successMessage={successMessage}
        />

        <ErrorDialog
          open={openErrorDialog}
          onClose={handleCloseErrorDialog}
          errorMessage={errorMessage}
        />
      </CardContent>
    </Card>
  );
};

export default GitHubRepoPage;
